import * as React from "react"
import Layout from "../../components/layoutAccount"
import '../../components/index.css'
import { TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "gatsby"
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LinkIcon from '@mui/icons-material/Link';


const pageStyles = {
    color: "#232129",
    padding: "24px 12px",
    fontFamily: "Roboto, sans-serif, serif",
    maxWidth: "95%",
}

const CredentialsModal = ({ callback }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    function login() {
        fetch('https://api.thefoodapi.com/login', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ email, password })
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(data => callback(data))
            } else {
                setError("Login failed")
            }
        })
    }

    return <div style={{
        position: "fixed",
        inset: "0",
        background: "rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1101",
        backdropFilter: "blur(1px)"
    }}>
        <div style={{
            width: "20rem",
            maxWidth: "95%",
            background: "white",
            borderRadius: "8px",
            padding: "2rem 1rem"
        }}>
            <div style={{
                background: 'url(/icons/icon-large.png)',
                backgroundSize: "cover",
                width: "84px",
                height: "36px",
                margin: "auto"
            }}></div>
            <h2 style={{ textAlign: "center" }}>Renew your credentials</h2>
            <TextField size="small" InputLabelProps={{ shrink: true }} fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginTop: "1rem" }} />
            <TextField size="small" InputLabelProps={{ shrink: true }} type="password" fullWidth label="Password" onChange={(e) => setPassword(e.target.value)} sx={{ marginTop: "1rem" }} />
            <div
                onClick={login}
                style={{
                    padding: "0.5rem",
                    background: "#007788",
                    textAlign: "center",
                    borderRadius: "4px",
                    color: "white",
                    marginTop: "1rem",
                    cursor: "pointer"
                }}><b>Login</b></div>
            {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
    </div>
}

const IndexPage = ({ location }) => {
    const [accountInfo, setAccountInfo] = React.useState(null)
    const [askCredentials, setAskCredentials] = React.useState(false)

    React.useEffect(() => {
        if (location.search.includes("session_id")) {
            fetch('https://api.thefoodapi.com/snapshot/download', {
                method: "GET",
                credentials: "include"
            })
        }

        fetch('https://api.thefoodapi.com/account/info', {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status === 403) {
                setAskCredentials(true)
            } else if (resp.status === 200) {
                resp.json().then(data => setAccountInfo(data))
            }
        })
    }, [])

    return (
        <Layout location={location}>
            {askCredentials && <CredentialsModal callback={(accountInfo) => {
                setAskCredentials(false)
                setAccountInfo(accountInfo)
            }} />}
            <div style={pageStyles}>
                <h2>Snapshots<span style={{ color: "#00778885" }}> - $299 one-time fee</span></h2>
                <div>Download a snapshot of our source code and database to use into your own applications.</div>
                <br />
                <div>
                    <div>The snapshot includes:</div>
                    <ul>
                        <li><b>A complete export of our Food Information database.</b> This includes food information such as: food name, categories, descriptions, links to images.</li>
                        <li><b>The source code of our Autocomplete endpoint.</b> Perfect if you want to host or customize the autocomplete endpoint.</li>
                    </ul>
                </div>
                <br />
                <div>The license allows you to: </div>
                <div style={{ marginTop: "1rem", padding: "1rem", borderRadius: "8px", border: "1px solid #00778845", maxWidth: "40rem", display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ color: "green", marginRight: "0.75rem" }} />Use the downloaded data for your own personal and commercial applications
                </div>
                <div style={{ marginTop: "1rem", padding: "1rem", borderRadius: "8px", border: "1px solid #00778845", maxWidth: "40rem", display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ color: "green", marginRight: "0.75rem" }} />No attribution required
                </div>
                <br />
                <div>The license does not allow you to:</div>
                <div style={{ marginTop: "1rem", padding: "1rem", borderRadius: "8px", border: "1px solid #00778845", maxWidth: "40rem", display: "flex", alignItems: "center" }}>
                    <ClearIcon sx={{ color: "red", marginRight: "0.75rem" }} />Resell or redistribute the downloaded data without modifying it first
                </div>
                <br />
                <div>For pictures and videos hosted on Pexel:
                    <div style={{ marginTop: "1rem", padding: "1rem", borderRadius: "8px", border: "1px solid #00778845", maxWidth: "40rem", display: "flex", alignItems: "center" }}>
                        <LinkIcon sx={{ color: "orange", marginRight: "0.75rem" }} /> <a href="https://www.pexels.com/license/" rel="nofollow">The terms and conditions </a>&#160;of Pexel apply.
                    </div>
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                    <div className="clickable" style={{
                        padding: "1rem",
                        background: "#007788",
                        fontWeight: "bold",
                        color: "white",
                        borderRadius: "4px"
                    }}
                        onClick={() => {
                            fetch('https://api.thefoodapi.com/snapshot/download', {
                                method: "GET",
                                credentials: "include"
                            }).then(resp => {
                                if (resp.status === 401) {
                                    fetch('https://api.thefoodapi.com/checkout-session', {
                                        method: "post",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        credentials: "include",
                                        body: JSON.stringify({ product: "snapshot" })
                                    }).then(resp => {
                                        if (resp.status === 200) {
                                            resp.json().then(data => {
                                                if (data.url) window.location = data.url
                                            })
                                        }
                                    })
                                }
                            })
                        }}>Download snapshot</div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const Head = () => <title>Pricing Page</title>
